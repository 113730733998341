import React, { useState, useEffect, useRef } from "react";
import "./PayModal.scss";
import { PayPalButtons } from "@paypal/react-paypal-js";
import { payRespect } from "api/GraveyardApi";

import PriceSelect from "./PriceSelect";

import PepeImage from "./assets/pepe.png";

const PayModal = ({ onClose, onPay }) => {
  const [price, setPrice] = useState(1);
  const [respectAmount, setRespectAmount] = useState(100);
  const [isPayModals, setIsPayModals] = useState(false);
  const priceRef = useRef();
  priceRef.current = price;

  const style = { label: "paypal", layout: "vertical" };

  const payPalCreateOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: priceRef.current,
          },
        },
      ],
    });
  };

  const payPalOnApprove = (data, actions) => {
    return actions.order.capture().then((details) => {
      const name = details.payer.name.given_name;
      alert(`Transaction completed by ${name}`);
    });
  };

  useEffect(() => {
    setPrice(respectAmount / 100);
  }, [respectAmount]);

  return (
    <>
      <div id="myModal" className="modal">
        <div className="modal-content">
          <span className="close" onClick={onClose}>
            &times;
          </span>
          <div className="modal-body pay-modal-body">
            <div className="pepe-image">
              <img src={PepeImage} alt="" />
            </div>
            <div className="pepe-controls">
              <PriceSelect
                value={respectAmount}
                onChange={(respect) => setRespectAmount(respect)}
              />
              <input className="pay-price-input" disabled value={`${price}$`} />
            </div>
            <div className="pepe-paybuttons">
              <PayPalButtons
                style={style}
                createOrder={payPalCreateOrder}
                onApprove={payPalOnApprove}
              />
            </div>
            <div className="pepe-disclaimer">
              <span>
                By clicking on the "pay" button, you accept our Disclaimer and
                Privacy Policy.
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PayModal;
