import React,{ useState } from "react";

import './MemeHeadstone.scss';

import respect1 from './assets/respects/pay respects 1.png';
import respect2 from './assets/respects/pay respects 2.png';
import respect3 from './assets/respects/pay respects 3.png';
import respect4 from './assets/respects/pay respects 4.png';
import respect5 from './assets/respects/pay respects 5.png';
import respect6 from './assets/respects/pay respects 6.png';
import respect7 from './assets/respects/pay respects 7.png';
import respect8 from './assets/respects/pay respects 8.png';
import respect9 from './assets/respects/pay respects 9.png';
import respect10 from './assets/respects/pay respects 10.png';
import respect11 from './assets/respects/pay respects 11.png';
import respect12 from './assets/respects/pay respects 12.png';
import respect13 from './assets/respects/pay respects 13.png';

const MemeHeadstone = ({ meme, onFpressed, isRespected }) => {
  function randomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
  }
  const images = [
    respect1,
    respect2,
    respect3,
    respect4,
    respect5,
    respect6,
    respect7,
    respect8,
    respect9,
    respect10,
    respect11,
    respect12,
    respect13,
  ];
  
  const [respectImage, setRespectImage] = useState(images[randomIntFromInterval(0,images.length)]);

  return (
    <div className="MemeHeadstone">
      <div className="MemeHeadstone-Body">
          <div className="MemeHeadstone-Image">
            <img src={meme.img} alt={meme.title}/>
          </div>
          <p className="MemeHeadstone-Title">
            <span>{meme.title}</span>
          </p>
          <div className="MemeHeadstone-RespectsButton" onClick={onFpressed}>
            F
          </div>
          <div className={`MemeHeadstone-Respect-Animated ${isRespected ? 'active': ''}`}>
            <div>
              <img src={respectImage} />
            </div>
            <p>+1 respect </p>
          </div>
          <div className="MemeHeadstone-Respects">
            respects {meme.respects}
          </div>
        </div>
        
    </div>
  );
}

export default MemeHeadstone;