import React from "react";
import "./PriceSelect.scss";

const PriceSelect = ({ onChange, value}) => {
  return (
    <div class="select-box">
      <div class="select-box__current" tabindex="1">
        {[100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 5000, 10000, 50000, 100000].map((price, key) => (
          <div class="select-box__value" key={key}>
            <input
              class="select-box__input"
              type="radio"
              id={key}
              value={price}
              name="Ben"
              checked={value === price ? true : false}
            />
            <p class="select-box__input-text">{price}</p>
          </div>
        ))}
        <img
          class="select-box__icon"
          src="http://cdn.onlinewebfonts.com/svg/img_295694.svg"
          alt="Arrow Icon"
          aria-hidden="true"
        />
      </div>
      <ul class="select-box__list">
        {[100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 5000, 10000, 50000, 100000].map((price, key) => (
          <li key={key}>
            <label class="select-box__option" for={key} aria-hidden="aria-hidden" onClick={() => onChange(price)}>
              {price}
            </label>
          </li>
        ))}
        
      </ul>
    </div>
  );
};

export default PriceSelect;
