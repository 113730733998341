import GraveyardApiService from './GraveyardApiService';

export const getMemes = ({ limit, offset, order }) => {
  return GraveyardApiService.get('/memes', { params: { limit, offset, order } }).then(response => response.data);
}

export const getMeme = (memeId) => {
  return GraveyardApiService.get(`/memes/${memeId}`).then(response => response.data);
}

export const payRespect = (memeId, respectAmount) => {
  return GraveyardApiService.post(`/memes/${memeId}/respect`, { respectAmount }).then(response => response.data);
}