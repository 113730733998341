import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import "./App.scss";

import Header from "./components/Header";
import Footer from "./components/Footer";

// Pages
import MainPage from "./pages/MainPage";
import AboutPage from "./pages/AboutPage";
import MemePage from "./pages/MemePage";
import { useEffect, useState } from "react";

const App = () => {
  const initialOptions = {
    "client-id": process.env.REACT_APP_PP_CLIENT_ID,
    currency: "USD",
    intent: "capture",
  };

  return (
    <PayPalScriptProvider options={initialOptions}>
      <div className="App">
        <Header />
        <Router>
          <Switch>
            <Route path="/about">
              <AboutPage />
            </Route>
            <Route path="/meme/:memeId">
              <MemePage />
            </Route>
            <Route path="/">
              <MainPage />
            </Route>
          </Switch>
        </Router>
        <Footer />
      </div>
    </PayPalScriptProvider>
  );
};

export default App;
