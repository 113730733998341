import React from "react";
import TombIcon from "./assets/TombIcon.png";
import "./AboutPage.scss";

const AboutPage = () => {
  return (
    <div className="AboutPage">
      <div className="AboutPage-Container">
        <div className="AboutPage-Welcome">
          <h1>Welcome, to the meme-grave</h1>
          <h2>
            The first and only online meme graveyard on the internet to remember
            the good old memes.
          </h2>

          <div className="AboutPage-TombIcon">
            <img src={TombIcon} />
          </div>
        </div>
        <div className="AboutPage-Description">
          <p className="AboutPage-Description-About">About</p>

          <p>
            Welcome to memegrave.com - the website that was made to determine
            which memes are the most popular based on user score.
          </p>

          <p>
            Anyone who has access to the internet is able to participate in this
            opportunity.
          </p>

          <p>
            So far this is just a sort of experiment/art/performance. We are not
            giving you any products to buy, we don't have any rights on the
            memes, as all of them are equal in rights for everyone in the
            internet as a part of mass culture.
          </p>

          <p>
            In case you have any exceptional rights on the image, please let us
            know by writing us on info.memegrave@gmail.com or here
            https://www.reddit.com/user/5ombi3
          </p>

          <p>
            The website will be slowly growing with more memes and features, as
            it starts on a low hand, to help it grow faster you can support our
            website by donating in the website through Patreon or any other
            features that would be added and updated as soon as they announced
            (investment in memes with dogecoins? who knows)
          </p>

          <p>
            Don’t know what to do here? Pay respects to your lovely memes! Don’t
            waste your time on reading this and respect some good old memes.
          </p>

          <p>
            You might be asking why it’s a graveyard and not a chart list. Well,
            memes do have a small period of life to be popular/hyped, etc. And
            whenever the memes go abroad to the borders of wide popularity it
            automatically becomes “dead”. That's why all of the memes are in the
            graveyard and each one of them has tombstones.
          </p>

          <p>
            Huge credits and kudos to Vlad who made this thing possible!
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
