import React from 'react';
import { Link } from 'react-router-dom';
import './MemeListItem.scss'

const MemeListItem = ({ meme }) => {
  return (
    <Link to={`/meme/${meme.memeId}`} className="MemeListItem">
      <div className="MemeListItem-Body">
        <div className="MemeListItem-Image">
          <img src={meme.thumbnail} alt={meme.title}/>
        </div>
        <div className="MemeListItem-Respects">
          respects {meme.respects}
        </div>
      </div>
      <p className="MemeListItem-Title">
        <span>{meme.title}</span>
      </p>
    </Link>
  );
}

export default MemeListItem;