import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import PayModal from "./components/PayModal";
import MemeHeadstone from "./components/MemeHeadstone";

import { payRespect, getMeme, getMemes } from "api/GraveyardApi";

import './MemePage.scss';

const MemePage = () => {
  const { memeId } = useParams();
  const [meme, setMeme] = useState();
  const [error, setError] = useState();
  
  const [isActivated, setIsActivated] = useState(false);

  const [isPayModalOpen, setPayModalOpen] = useState(false);

  const [voted, setVoted] = useState([]);

  useEffect(() => {
    getMeme(memeId).then(meme => {
      setMeme(meme);
    });
  }, []);

  useEffect(() => {
    let votedStorage = localStorage.getItem('gy_voted');
    if(!votedStorage) {
      localStorage.setItem('gy_voted', JSON.stringify([]));
      votedStorage = "[]";
    }
    setVoted(JSON.parse(votedStorage));
  }, []);

  const checkIfInStorage = (memeId) => {
    let votedStorage = localStorage.getItem('gy_voted');
    if(!votedStorage) {
      localStorage.setItem('gy_voted', JSON.stringify([]));
      votedStorage = "[]";
    }

    const arr = JSON.parse(votedStorage);

    return arr.includes(memeId.toString());
  }

  const _handleFtoPayRespect = (e) => {
    if(e.code === 'KeyF' && !isActivated) {
      setPayModalOpen(1)
    }
  }

  const _payRespect = (amount) => {
    payRespect(memeId, amount).then(response => {
      getMeme(memeId).then(meme => {
        setMeme(meme);
        setIsActivated(true)
      });
    })
  }

  useEffect(() => {
    document.addEventListener("keydown", _handleFtoPayRespect, false);
    return function cleanup() {
      document.removeEventListener("keydown", _handleFtoPayRespect, false);
    };
  }, [])

  return (  
    <div className="MemePage">
      <div className="MemePage-ContentWrapper">
        <p className="MemePage-Direction"> press <span>F</span> to pay respects </p>
        {meme && <MemeHeadstone meme={meme} isRespected={isActivated} onFpressed={() => _payRespect(1)}/>}
      </div>
      {isPayModalOpen && 
          <PayModal
            onClose={() => setPayModalOpen(false)}
            onPay={(amount) => _payRespect(amount)}
          />
        }
    </div>
  )
}

export default MemePage;