import React from "react";

import OrderIcon from './order.png';
import './Order.scss';

const OrderSelect = ({ order, onChange }) => {
  return (  
    <div className="Order">
      <div>
        <img src={OrderIcon} />
        <span className="Order-Text">filter</span>
      </div>
      <div className="Order-Selector">
        <ul>
          <li onClick={() => onChange('respects')} className={order === 'respects' ? 'active' : ''}>sort by respects</li>
          <li onClick={() => onChange('title')} className={order === 'title' ? 'active' : ''}>sort by name</li>
        </ul>
      </div>
    </div>
  )
}

export default OrderSelect;