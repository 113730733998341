import React, { useState, useEffect } from "react";  
import MemeListItem from "../../components/MemeListItem";
import OrderSelect from "./components/Order";
import { getMemes } from '../../api/GraveyardApi';
import './MainPage.scss';

const MainPage = () => {
  const [memeList, setMemeList] = useState([])
  const [limit, setLimit] = useState(24);
  const [offset, setOffset] = useState(0);
  const [order, setOrder] = useState('respects');
  const [total, setTotal] = useState(0);
  
  useEffect(() => {
    console.log(offset);
    getMemes({ limit,offset,order }).then(({list, offset, limit, total}) => {
      setMemeList([...memeList,...list]);
      setTotal(total);
    });
  }, [limit, offset]);

  useEffect(() => {
    if(limit == 24 && offset == 0 && total !== 0) {
      getMemes({ limit,offset,order }).then(({list, offset, limit, total}) => {
        setMemeList([...list]);
        setTotal(total);
      });
    }
    setLimit(24);
    setOffset(0);

  }, [order])

  function onBottomScroll(e) {
    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
      const data = offset+24;
      setOffset(data);
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', onBottomScroll, false);

    return function cleanup() {
      window.removeEventListener('scroll', onBottomScroll);
    }
  }, [offset])

  return (
    <div className="MainPage">
      <div className="MainPage-ContentWrapper">
        <OrderSelect order={order} onChange={(value) => setOrder(value)}/>
        <div className="MemeList">
          {memeList.map((meme,index) => (
            <MemeListItem key={index} meme={meme}/>
          ))}
        </div>
      </div>
    </div>
  )
};

export default MainPage;