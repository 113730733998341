import React from 'react';
import './header.scss';

import MainIcon from './assets/MainIcon.png'

const Header = () => {
  return ( 
    <div className="header">
      <a href="/" className="link">
        <img src={MainIcon} alt="main"/>
      </a>
      <a href="/about" className="link">
        <p className="link__text">about</p>
      </a>
    </div>
  );
};

export default Header;