import React from 'react';

const Footer = () => {
  return ( 
    <div>
      
    </div>
  );
};

export default Footer;